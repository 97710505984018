import { css } from 'styled-components';

export const underline = () => css`
	text-decoration: none;
	border-bottom: 2px solid;
`;

export const noUnderline = () => css`
	text-decoration: none;
	border-bottom: none;
`;

export const hideUnderline = () => css`
	text-decoration: none;
	border-bottom: 2px solid transparent;
`;
