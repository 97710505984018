import styled, { css } from 'styled-components';

import theme from 'moduleAlias/project/src/styles/theme';

export const gridItemCss = css`
	display: block;
	margin-left: ${theme.halfSpace};
	margin-right: ${theme.halfSpace};
`;

export const GridItem = styled.div`
	${gridItemCss};
`;
