import { css } from 'styled-components';

export const cleanButton = css`
	background: none;
	border: none;
	padding: 0;
	color: inherit;
	font-weight: inherit;
	line-height: inherit;
	text-align: left;
	text-transform: none;
`;
