import styled from 'styled-components';

import {
	createUnderliner,
	createHoverable,
} from '@dop/shared/components/link/blockElements';

import { Link } from './link';
import { hoverEtc } from '../../styles/mixins/pseudo';

export const BlockLink = styled(Link)`
	display: block;
	border: none;
	${({ $underline }) =>
		// disable underline when nested into h{2,3,4}
		$underline === false &&
		hoverEtc`
			border-bottom: none;
	`};
`;

BlockLink.Underliner = createUnderliner(BlockLink);
BlockLink.withHover = (styles) => (Component) =>
	createHoverable(styles)(Component)(BlockLink);
