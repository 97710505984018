import { css } from 'styled-components';

import {
	extraExtraExtraLarge,
	mediumLarge,
	mq,
} from '@dop/shared/styleHelpers/mediaQueries';

export const gridContainerCss = css`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
`;

export const gridContainerStretchItems = css`
	align-items: stretch;
`;

export const gridContainerAlignItemsToEnd = css`
	align-items: flex-end;
`;

export const gridContainerAlignItemsCenter = css`
	align-items: center;
`;

export const gridContainerCenterItems = css`
	justify-content: center;
`;

export const gridContainerItemsToEnd = css`
	justify-content: flex-end;
`;

export const gridContainerSpaceBetweenItems = css`
	justify-content: space-between;
`;

export const gridContainerSingleLine = css`
	flex-wrap: nowrap;
`;

export const gridContainerReverse = css`
	flex-direction: row-reverse;
	justify-content: flex-end;
`;

export const gridContainerReducedWidth = css`
	${mq({ from: mediumLarge })`width: 85%;`};
	${mq({ from: extraExtraExtraLarge })`width: 75%;`};
`;

interface Props {
	className: string;
	children: React.ReactNode;
}

export const GridContainer = ({
	className,
	children,
	...props
}: Props): React.ReactElement | null => {
	if (children == null) return null;

	return (
		<div
			css={gridContainerCss}
			className={['gridContainer', className].join(' ')}
			{...props}
		>
			{children}
		</div>
	);
};
