import styled, { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { StyledComponentPropsWithoutRef } from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';

import {
	BaseStaticStyleProps,
	baseStaticStyleMap,
	baseStyle,
} from '../base/Base';
import {
	CapSize,
	defaultTextTransition,
	FontVariantNumeric,
} from './typographyDefinitions';
import { getCSSFromStyleProps } from '../base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '../base/StyleProps.types';
import {
	getFontWeightCSS,
	getCapSizeCSS,
	getFontVariantNumericCSS,
	getInteractiveTextDecorationLineCSS,
	getFontStyleCSS,
	getNormalTextColorCSS,
	getTextDecorationLineCSS,
	getTextAlignCSS,
	getTextTransformCSS,
	getHoverTextColorCSS,
	getActiveTextColorCSS,
	getHyphensCss,
	getWordBreakCss,
	getWhiteSpaceCSS,
} from './typographyStyleFunctions';
import { TextColor } from '../colorTheme/ColorTheme.types';
import { Outline } from '../planeDivision/planeDivisionDefinitions';
import {
	getInteractiveOutlineCSS,
	getNormalOutlineCSS,
} from '../planeDivision/planeDivisionStyleFunctions';

export type TextBlockStaticStyleProps = BaseStaticStyleProps & {
	$capSize?: CapSize;
	$fontVariantNumeric?: FontVariantNumeric;
	$fontStyle?: CSSProperty.FontStyle;
	$textAlign?: CSSProperty.TextAlign;
	$textTransform?: CSSProperty.TextTransform;
	$wordBreak?: CSSProperty.WordBreak;
	$hyphens?: CSSProperty.Hyphens;
	$whiteSpace?: CSSProperty.WhiteSpace;
};

export type TextBlockInteractiveStyleProps = {
	$fontWeight?: CSSProperty.FontWeight;
	$textDecorationLine?: Extract<
		CSSProperty.TextDecorationLine,
		CSSProperty.All | 'none' | 'underline'
	>;
	$color?: TextColor;
	$outline?: Outline;
};

export type TextBlockComponentStyleProps = ComponentStyleProps<
	TextBlockStaticStyleProps,
	TextBlockInteractiveStyleProps
>;

export const textBlockStaticStyleMap: StyleMap<
	TextBlockStaticStyleProps & TextBlockInteractiveStyleProps
> = {
	...baseStaticStyleMap,
	$transition: (transition = defaultTextTransition) =>
		baseStaticStyleMap.$transition(transition),
	$outline: getNormalOutlineCSS,
	$capSize: getCapSizeCSS,
	$fontWeight: getFontWeightCSS,
	$fontStyle: getFontStyleCSS,
	$fontVariantNumeric: getFontVariantNumericCSS,
	$textDecorationLine: getTextDecorationLineCSS,
	$color: getNormalTextColorCSS,
	$textAlign: getTextAlignCSS,
	$textTransform: getTextTransformCSS,
	$wordBreak: getWordBreakCss,
	$hyphens: getHyphensCss,
	$whiteSpace: getWhiteSpaceCSS,
};

export const textBlockInteractiveStyleMap: StyleMap<TextBlockInteractiveStyleProps> =
	{
		$outline: getInteractiveOutlineCSS('normal'),
		$fontWeight: getFontWeightCSS,
		$color: textBlockStaticStyleMap.$color,
		$textDecorationLine: getInteractiveTextDecorationLineCSS,
	};

const textBlockStyleMap: ComponentStyleMap<
	TextBlockStaticStyleProps,
	TextBlockInteractiveStyleProps
> = {
	normal: textBlockStaticStyleMap,
	hover: {
		...textBlockInteractiveStyleMap,
		$outline: getInteractiveOutlineCSS('hover'),
		$color: getHoverTextColorCSS,
	},
	active: {
		...textBlockInteractiveStyleMap,
		$outline: getInteractiveOutlineCSS('active'),
		$color: getActiveTextColorCSS,
	},
	focus: textBlockInteractiveStyleMap,
	checked: textBlockInteractiveStyleMap,
	disabled: textBlockInteractiveStyleMap,
};

/**
 * Component for styling blocks of text
 *
 * Responsibilities:
 *
 * 1. Make text block start at cap height and end at base line.
 * 2. Text styling
 * 3. If a `<TextStyle>` element has set `color` or `font-size`/`line-height` variables text block should use those as default values.
 *
 * Note: every block of text should be wrapped
 * in this component to make sure spacing before
 * and after text fits snugly
 */
export const TextBlock = styled.span<TextBlockComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			display: block;
			${getCSSFromStyleProps(textBlockStyleMap, props)};
		`;
	}};
`;

export type TextBlockProps = StyledComponentPropsWithoutRef<typeof TextBlock>;
