import styled from 'styled-components';

import { isEmpty } from '@dop/shared/helpers/immutableHelpers';
import { ContentContainer } from '@dop/shared/components/layout/contentContainer';

import { ActualBlockList } from './actualBlockList';
import { Heading } from '../heading';
import { usePageDataSelector } from '@/globals/dataProviders/usePageDataSelector';
import { getIn } from '@dop/shared/helpers/immutableHelpersWithArgs';

const ActualBlockContainer = styled.div``;
const ActualBlockHeading = styled(Heading).attrs(() => ({ level: 2 }))``;

export const ActualBlock = () => {
	const blockList = usePageDataSelector(getIn(['actualBlocks', 'blockList']));

	if (isEmpty(blockList)) return null;

	return (
		<ActualBlockContainer>
			<ContentContainer>
				<ActualBlockHeading>{'Actueel'}</ActualBlockHeading>
			</ContentContainer>
			<ActualBlockList blockList={blockList} />
		</ActualBlockContainer>
	);
};
