import { css } from 'styled-components';

import { IconChevronRight } from '@dop/ui-icons/svg/chevronRight';
import { ContentContainer } from '@dop/shared/components/layout/contentContainer';
import { Media } from '@dop/shared/components/media/media';

import { zwart, rainbow } from '../../styles/colors';
import { BlockLink } from '../link/blockLink';
import { underline } from '../../styles/includes/underline';
import { hoverEtc } from '../../styles/mixins/pseudo';
import { getIn } from '@dop/shared/helpers/immutableHelpersWithArgs';
import { usePageDataSelector } from '@/globals/dataProviders/usePageDataSelector';

export const HomeCoronaBanner = () => {
	const coronaInfo = usePageDataSelector(getIn(['alertBanner']));
	if (!coronaInfo) {
		return null;
	}

	const { title, subtitle, link } = coronaInfo.toJSON();

	return (
		<div
			css={css`
				background-color: ${rainbow.oranje};
				color: ${zwart};
				padding-top: 0.8rem;
				padding-bottom: 0.8rem;
				line-height: 1.4;
			`}
			data-ui-test="uiCoronaBanner"
		>
			<ContentContainer>
				<span
					css={css`
						margin-right: 1rem;
					`}
				>
					<span
						className="h2"
						css={css`
							color: inherit;
							margin: 0;
							vertical-align: baseline;
							padding-inline-end: 1rem;
						`}
					>
						{title}
					</span>
					{subtitle ? ` ${subtitle}` : ''}
				</span>{' '}
				{link != null && (
					<BlockLink
						to={link.href}
						css={css`
							display: inline-block;
							${hoverEtc`
							color: inherit;
						`}
						`}
					>
						<Media inline alignItems="flex-start">
							<Media.Text>
								<span css={underline}>{link.text}</span>
							</Media.Text>
							<Media.Figure>
								<IconChevronRight
									css={css`
										height: 1.2rem;
										width: 1.2rem;
										margin: 0.1rem 0 0.1rem 0.4rem;
										fill: currentColor;
										transition: transform 200ms;

										${BlockLink}:hover &,
									${BlockLink}:focus &,
									${BlockLink}:active & {
											transform: translateX(2px);
										}
									`}
								/>
							</Media.Figure>
						</Media>
					</BlockLink>
				)}
			</ContentContainer>
		</div>
	);
};
